import environment from 'environments/environment.dev';
import { FAPI_MACHINE_ENDPOINT } from 'libs/constants/b2bApiEndpoints';

import HttpClient from './apiHttpClient';

const fapiClient = new HttpClient(environment.shopFAPIUrl);

export const machineService = {
  getMachinesList({ pageNumber, pageSize, sortOrder }) {
    const queryParams = new URLSearchParams({
      pageNumber,
      pageSize,
      sortOrder,
    });
    const url = `${FAPI_MACHINE_ENDPOINT}/getall?${queryParams.toString()}`;

    return fapiClient.get(url);
  },
};
