import './styles.scss';

import accessoriesDetergentsImage from 'assets/img/acc-detergents-image.webp';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import BlockTwoCol from '../../../components/BlockTwoCol';
import CustomButton from '../../../components/CustomButton';

const ContentWrapper = (materialNumber, options = {}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const description = t(
    'productDetails.accessoriesDetergents.description'
  ).split('\n');

  const descriptionLines = description
    .map((v) => v.trim())
    .map((text, index) => ({ index, text }));

  return (
    <div className="accessories-detergents__content pt-md-6 pb-5 pb-md-6">
      <h2 className="block-two-col__header">
        {t('productDetails.accessoriesDetergents.title')}
      </h2>
      <div className="block-two-col__content">
        {descriptionLines.map((line) => (
          <p key={line.index}>{line.text}</p>
        ))}
      </div>
      <div className="block-two-col__footer">
        <div className="accessories-detergents__btn-wrapper">
          {options.showAccessoriesButton && (
            <CustomButton
              block
              onClick={() =>
                history.push(
                  linkGenerator(`/product/${materialNumber}/accessories`)
                )
              }
            >
              {t('productDetails.accessoriesDetergents.button.showAccessories')}
            </CustomButton>
          )}
          {options.showDetergentsButton && (
            <CustomButton
              block
              onClick={() =>
                history.push(
                  linkGenerator(`/product/${materialNumber}/detergents`)
                )
              }
            >
              {t('productDetails.accessoriesDetergents.button.showDetergents')}
            </CustomButton>
          )}
        </div>
      </div>
    </div>
  );
};

function ImagesWrapper() {
  return (
    <div className="accessories-detergents__image">
      <img
        alt="accessories-detergents"
        loading="lazy"
        src={accessoriesDetergentsImage}
        width="100%"
        height="auto"
      />
    </div>
  );
}

function AccessoriesDetergents(props) {
  const { materialNumber, options } = props;
  return (
    <BlockTwoCol
      className="accessories-detergents"
      left={ContentWrapper(materialNumber, options)}
      right={ImagesWrapper()}
    />
  );
}

AccessoriesDetergents.propTypes = {
  materialNumber: PropTypes.string,
  options: PropTypes.shape({
    showAccessoriesButton: PropTypes.bool,
    showDetergentsButton: PropTypes.bool,
  }),
};

AccessoriesDetergents.defaultProps = {
  materialNumber: '',
  options: {},
};

export default AccessoriesDetergents;
