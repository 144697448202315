import { NotificationManager } from 'components/Notification';
import { SESSION_BASED_EXPIRY_TIME } from 'libs/constants';
import { isValidArray } from 'libs/utils/array';
import {
  clearCookieForPunchoutSystem,
  getCookie,
  renewCookie,
  setCookie,
} from 'libs/utils/cookies';
import { hasAllPropertiesValid } from 'libs/utils/object';
import {
  convertCxmlDoc,
  submitOciForm,
  submitProcurementForm,
} from 'libs/utils/punchoutHandler';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { punchoutService } from 'services';
import { selectUserId } from 'store/selectors/authenticationSelectors';
import { selectCartData } from 'store/selectors/cartSelector';
import {
  selectHasSessionBasedShoppingCart,
  selectIsProcurementTechnicalUserCxml,
  selectUserOriginalLanguage,
} from 'store/selectors/userSelector';
import { authenticationActions } from 'store/slices/authenticationSlice';
import { getMiniCart } from 'store/slices/cartSlice';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_MANUFACTURER = 'Alfred K&#228;rcher SE &amp; Co. KG';

const handleGetCachedData = async () => {
  const response = await punchoutService.getCachedCxmlData();
  return response?.data?.cxml;
};

const handleCleanCachedData = async () =>
  punchoutService.deleteCachedCxmlData();

const PunchoutContext = React.createContext();

const LOCAL_STORAGE_CART_SESSION_KEY = 'sessionId';

let sessionCookieKey = '';

export const getSessionCookieKey = () => sessionCookieKey;

const Provider = ({ children, userId }) => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPreparingCXMLData, setIsPreparingCXMLData] = useState(false);

  const isProcurementUserCxml = useSelector(
    selectIsProcurementTechnicalUserCxml
  );
  const cartData = useSelector(selectCartData);
  const hasSessionBasedShoppingCart = useSelector(
    selectHasSessionBasedShoppingCart
  );
  const language = useSelector(selectUserOriginalLanguage);

  const COOKIE_KEY = useMemo(() => {
    return isProcurementUserCxml
      ? 'PUNCHOUT_SESSION_ID'
      : `${LOCAL_STORAGE_CART_SESSION_KEY}-${userId}`;
  }, [isProcurementUserCxml, userId]);

  const localSessionId = getCookie(COOKIE_KEY);
  const urlFromCookie = getCookie('HOOK_URL');

  const clearShoppingCartSession = useCallback(() => {
    if (isProcurementUserCxml) {
      handleCleanCachedData().then(({ error }) => console.error(error));
    }
    clearCookieForPunchoutSystem();
  }, [isProcurementUserCxml]);

  const renewShoppingCartSession = useCallback(() => {
    renewCookie(COOKIE_KEY, SESSION_BASED_EXPIRY_TIME);
  }, [COOKIE_KEY]);

  const sessionId = useMemo(() => {
    if (localSessionId) {
      return localSessionId;
    }

    const session = uuidv4();

    const expiredTime = new Date().getTime() + SESSION_BASED_EXPIRY_TIME;
    setCookie({ name: COOKIE_KEY, value: session, expires: expiredTime });

    return session;
  }, [COOKIE_KEY, localSessionId]);

  const onSubmitFailedHandler = () => {
    NotificationManager.error({
      message: 'notification.error.submitOrder',
      description: 'notification.error.noPunchoutUrlFound',
    });
  };

  const onSubmitCartViaOCI = useCallback(() => {
    // must check user is tech and have valid session
    if (urlFromCookie) {
      submitOciForm({ cartData, url: urlFromCookie });
      setIsSubmitted(true);
    } else {
      onSubmitFailedHandler();
    }
  }, [cartData, urlFromCookie]);

  const prepareDataForCXMLTemplate = useCallback(
    (convertedCachedData) => {
      const { cXML } = convertedCachedData;
      const { Header, Request } = cXML;

      const payloadId = [uuidv4(), '@', window.location.hostname].join('');
      const timestamp = new Date().toISOString();
      const templateType = getCookie('PUNCHOUT_TEMPLATE_TYPE');
      const isCoupaTemplate = templateType?.toLowerCase() === 'coupa';

      return {
        type: templateType,
        buyerCookie: Request?.PunchOutSetupRequest?.BuyerCookie,
        payloadId,
        timeStamp: timestamp,
        culture: isCoupaTemplate ? language : 'de-DE',
        supplier: {
          domain: Header?.To?.Credential?.domain,
          value: Header?.To?.Credential?.Identity,
        },
        sender: {
          domain: Header?.To?.Credential?.domain,
          value: Header?.To?.Credential?.Identity,
        },
        buyer: {
          domain: Header?.From?.Credential?.domain,
          value: Header?.From?.Credential?.Identity,
        },
        manufacturerName: DEFAULT_MANUFACTURER,
        unitOfMeasure: isCoupaTemplate ? 'EA' : 'C62',
      };
    },
    [language]
  );

  const onSubmitCartToProcurement = useCallback(async () => {
    try {
      setIsPreparingCXMLData(true);
      const cachedData = await handleGetCachedData();
      const convertedCachedData = convertCxmlDoc(cachedData);
      const cxmlData = prepareDataForCXMLTemplate(convertedCachedData);
      const { data: poom } = await punchoutService.generateDynamicCxmlTemplate({
        cxmlData,
        sessionId,
      });

      setIsPreparingCXMLData(false);
      if (hasAllPropertiesValid(convertedCachedData)) {
        submitProcurementForm(convertedCachedData, poom);
        setIsSubmitted(true);
      } else {
        console.error('cached cxml data:', cxmlData);
        onSubmitFailedHandler();
      }
    } catch (err) {
      setIsPreparingCXMLData(false);
      console.error('error while handling cxml cached data', err);
    }
  }, [sessionId, prepareDataForCXMLTemplate]);

  const onReturnToProcurement = useCallback(async () => {
    const emptyCartData = {
      items: [],
      currencyUnit: null,
      additionalCost: 0,
      cartSubTotal: 0,
      cartTotal: 0,
      cartTotalVAT: 0,
      shippingCosts: 0,
      voucherValue: {
        code: '',
        totalDiscount: '',
        materialNumbers: [],
        categoryIds: [],
      },
      vat: 0,
    };
    try {
      const cachedData = await handleGetCachedData();
      const convertedCachedData = convertCxmlDoc(cachedData);
      const cxmlData = prepareDataForCXMLTemplate(convertedCachedData);
      const { data: poom } = await punchoutService.generateDynamicCxmlTemplate({
        cxmlData,
        sessionId,
      });

      if (hasAllPropertiesValid(convertedCachedData)) {
        submitProcurementForm(emptyCartData, poom);
        setIsSubmitted(true);
      } else {
        console.error('cached cxml data:', convertedCachedData?.cxml);
        onSubmitFailedHandler();
      }
    } catch (err) {
      console.error('error while handling cxml cached data', err);
    }
  }, [sessionId, prepareDataForCXMLTemplate]);

  const onSubmitPunchoutData = useCallback(() => {
    if (cartData && isValidArray(cartData?.items)) {
      if (isProcurementUserCxml) {
        onSubmitCartToProcurement();
      } else {
        onSubmitCartViaOCI();
      }
    }
  }, [
    cartData,
    isProcurementUserCxml,
    onSubmitCartViaOCI,
    onSubmitCartToProcurement,
  ]);

  useEffect(() => {
    if (hasSessionBasedShoppingCart) {
      sessionCookieKey = COOKIE_KEY;
    }
  }, [hasSessionBasedShoppingCart, COOKIE_KEY]);

  useEffect(() => {
    if (hasSessionBasedShoppingCart && sessionId) {
      dispatch(getMiniCart());
    }
  }, [dispatch, hasSessionBasedShoppingCart, sessionId]);

  useEffect(() => {
    if (isSubmitted) {
      // dispatch(emptyCart());
      clearShoppingCartSession();
      dispatch(authenticationActions.signOut());
    }
  }, [dispatch, isSubmitted, clearShoppingCartSession]);

  const contextValue = useMemo(
    () => ({
      sessionId,
      isPreparingCXMLData,
      clearShoppingCartSession,
      renewShoppingCartSession,
      onSubmitPunchoutData,
      onReturnToProcurement,
    }),
    [
      sessionId,
      isPreparingCXMLData,
      clearShoppingCartSession,
      renewShoppingCartSession,
      onSubmitPunchoutData,
      onReturnToProcurement,
    ]
  );

  return (
    <PunchoutContext.Provider value={contextValue}>
      {children}
    </PunchoutContext.Provider>
  );
};

const ProviderMemo = React.memo(Provider);

export default PunchoutContext;

export const PunchoutProvider = ({ children }) => {
  const userId = useSelector(selectUserId);

  if (userId) {
    return <ProviderMemo userId={userId}>{children}</ProviderMemo>;
  }

  return children;
};
