import B2bLoadingFullPage from 'components/B2bLoadingFullPage';
import { getInfoFromUrl } from 'libs/utils/punchoutHandler';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authenticationActions } from 'store/slices/authenticationSlice';

export default function TechnicalLoginPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    const { pathname = '' } = window?.location;

    const pathParts = pathname.split('/');
    // Get the last part of the path
    const encodedSecrets = pathParts[pathParts.length - 1];
    // Remove the first character of the encoded string
    const trimmedEncodedSecrets = encodedSecrets.slice(1);
    const decodedSecrets = window.atob(trimmedEncodedSecrets);

    const queryKeys = ['username', 'password'];
    const punchoutType = pathParts[pathParts.length - 2];

    const punchout = getInfoFromUrl(decodedSecrets, queryKeys, {
      type: punchoutType,
    });

    dispatch(
      authenticationActions.signIn({
        username: decodeURIComponent(punchout?.username),
        password: decodeURIComponent(punchout?.password),
      })
    );
  }, [dispatch]);

  return <B2bLoadingFullPage />;
}
