import './styles.scss';

import { Col, Row, Space } from 'antd';
import sparePartImage from 'assets/img/spare-parts.webp';
import { NotificationManager } from 'components/Notification';
import useSessionBased from 'hooks/useSessionBased';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ProductService } from 'services';
import { selectIsProcurementTechnicalUserCxml } from 'store/selectors/userSelector';

import BlockTwoCol, { displayTypes } from '../BlockTwoCol';
import CustomButton from '../CustomButton';

const ContentWrapper = (materialNumber, translation) => {
  const { t } = useTranslation();
  const { sessionId } = useSessionBased();
  const [isFetching, setIsFetching] = useState(false);
  const isTechnicalUserCXML = useSelector(selectIsProcurementTechnicalUserCxml);
  const sparePartsOpenMode = isTechnicalUserCXML ? '_self' : '_blank';

  const getDisisLink = useCallback(async () => {
    try {
      setIsFetching(true);
      const { data } = await ProductService.getProductSpareParts(
        materialNumber,
        sessionId
      );
      setIsFetching(false);
      window.open(data, sparePartsOpenMode);
    } catch (error) {
      setIsFetching(false);
      console.error('Error fetching spare parts:', error?.message);
      NotificationManager.error({
        message: 'notification.error.getProductSpareParts',
        description: error?.response?.data,
      });
    }
  }, [materialNumber, sessionId, sparePartsOpenMode]);

  const description = t('productDetails.spareParts.description').split('\n');
  const descriptionLines = description
    .map((v) => v.trim())
    .map((text, index) => ({ index, text }));

  const { allSparePart } = translation;
  const allSparePartText = t(
    `productDetails.spareParts.button.${allSparePart}`
  );
  return (
    <div>
      <h2 className="block-two-col__header">
        {t('productDetails.spareParts.title')}
      </h2>
      <div className="block-two-col__content mb-5">
        {descriptionLines.map((line) => (
          <p key={line.index}>{line.text}</p>
        ))}
      </div>
      <div className="block-two-col__footer">
        <Space className="block-two-col__btn-wrapper" direction="vertical">
          <CustomButton onClick={getDisisLink} block loading={isFetching}>
            {allSparePartText}
          </CustomButton>
        </Space>
      </div>
    </div>
  );
};

function ImagesWrapper() {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <div
          className="spare-part-bg-img"
          style={{ backgroundImage: `url(${sparePartImage})` }}
        />
      </Col>
    </Row>
  );
}

function B2becSpareParts(props) {
  const { className, translation, materialNumber } = props;
  return (
    <div className="spare-parts-component__item">
      <BlockTwoCol
        className={className}
        type={displayTypes.NORMAL}
        left={ImagesWrapper()}
        right={ContentWrapper(materialNumber, translation)}
      />
    </div>
  );
}

B2becSpareParts.defaultProps = {
  materialNumber: null,
  className: 'spare-parts',
  translation: {
    allSparePart: 'allSparePart',
  },
};

B2becSpareParts.propTypes = {
  materialNumber: PropTypes.string,
  className: PropTypes.string,
  translation: PropTypes.shape({ allSparePart: PropTypes.string }),
};

export default B2becSpareParts;
