import environment from 'environments/environment.dev';

import HttpClient from './apiHttpClient';

const SETUP_ENDPOINT = '/v1.0/setup';

const fapiClient = new HttpClient(environment.shopFAPIUrl);

export const setupService = {
  /**
   * Retrieves the setup data from the server
   * @async
   * @function
   * @returns {Promise<ApiResponse>} The setup data response
   * @throws {Error} If the request fails
   * @example
   * try {
   *   const response = await setupService.getSetupData();
   *   console.log(response.data);
   * } catch (error) {
   *   console.error('Failed to fetch setup data:', error);
   * }
   */
  getSetupData() {
    return fapiClient.get(SETUP_ENDPOINT);
  },
};
