export function setCookie({
  name,
  value,
  expires,
  sameSite,
  secure,
  httpOnly,
}) {
  const expireAt = expires && new Date(expires).toUTCString();

  let cookieString = `${name}=${value};expires=${expireAt};path=/;`;

  if (sameSite) {
    cookieString += `SameSite=${sameSite};`;
  }
  if (secure) {
    cookieString += `Secure=${secure};`;
  }
  if (httpOnly) {
    cookieString += `HttpOnly=${httpOnly};`;
  }

  document.cookie = cookieString;
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const clearCookie = (cname) => {
  const expiresAt = new Date(-1).toUTCString();

  setCookie({ name: cname, value: '', expires: expiresAt });
};

export const renewCookie = (cname, extendTime) => {
  if (typeof window !== 'undefined' && window.cookieStore) {
    window.cookieStore.get(cname).then((cookie) => {
      if (cookie) {
        const { expires: expirationTime, ...restInfo } = cookie;

        // if less than 3 minute, renew the cookie's expiration time
        const isGonnaExpired =
          (new Date(expirationTime) - new Date()) / 3600000 <= 0.05;

        if (isGonnaExpired) {
          const extendedExpiryTime = new Date().getTime() + extendTime;
          setCookie({ expires: extendedExpiryTime, ...restInfo });
        }
      }
    });
  }
};

export const getCookieData = (cname) => {
  if (cname) {
    if (typeof window !== 'undefined' && window.cookieStore) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const result = await window.cookieStore.get(cname);
          resolve(result);
        }, 1000);
      });
    }
  }
  return null;
};

export const clearCookieForPunchoutSystem = () => {
  clearCookie('PUNCHOUT_SESSION_ID');
  clearCookie('PUNCHOUT_TEMPLATE_TYPE');
};
