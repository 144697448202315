import environment from 'environments/environment.dev';
import { FAPI_REPORT_ENDPOINT } from 'libs/constants/b2bApiEndpoints';

import HttpClient from './apiHttpClient';

const fapiClient = new HttpClient(environment.shopFAPIUrl);

export const reportService = {
  exportShoppingCart({ sessionId }) {
    const searchParams = new URLSearchParams();
    let url = `${FAPI_REPORT_ENDPOINT}/summary`;

    if (sessionId) {
      searchParams.append('sessionId', sessionId);
      url = url.concat(`?${searchParams}`);
    }

    return fapiClient.get(url);
  },
};
