import './digitalData';

import B2becSpinner from 'components/B2becSpinner';
import AddProductToWishListModal from 'components/Wishlist/AddProductToWishlistModal';
import useCalculateScrollbarWidth from 'hooks/useCalculateScrollbarWidth';
import useCheckForRedirectUrlAfterLogin from 'hooks/useCheckForRedirectUrlAfterLogin';
import { setDateFormat } from 'libs/utils/formatDate';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsCheckingAuthentication,
  selectIsLoggedIn,
} from 'store/selectors/authenticationSelectors';
import {
  selectDateFormat,
  selectIsCurrentTimeInSAPDowntime,
} from 'store/selectors/configSelector';
import { selectIsFetchingUserAssignedRoles } from 'store/selectors/roleSelector';
import { selectIsAddProductToWishlistModalVisible } from 'store/selectors/wishlistSelectors';
import { authenticationActions } from 'store/slices/authenticationSlice';
import { selectIsFetchingSetupData } from 'store/slices/setupSlice';

import {
  ActionsDispatcher,
  SAPDownTime,
} from './components/PostAuthentication';
import RouterRenderer from './routes';

export default function App() {
  const dispatch = useDispatch();
  const isCheckingAuthentication = useSelector(selectIsCheckingAuthentication);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isAddProductToWishlistModalVisible = useSelector(
    selectIsAddProductToWishlistModalVisible
  );
  const shouldShowSAPDownTimePopup = useSelector(
    selectIsCurrentTimeInSAPDowntime
  );
  const isFetchingSetupData = useSelector(selectIsFetchingSetupData);
  const dateFormat = useSelector(selectDateFormat);
  const isFetchingUserAssignedRoles = useSelector(
    selectIsFetchingUserAssignedRoles
  );

  setDateFormat(dateFormat);
  useCheckForRedirectUrlAfterLogin();
  useCalculateScrollbarWidth();

  useEffect(() => {
    dispatch(authenticationActions.checkAuthentication());
  }, [dispatch]);

  useCalculateScrollbarWidth();

  if (
    isCheckingAuthentication ||
    isFetchingSetupData ||
    isFetchingUserAssignedRoles
  ) {
    return <B2becSpinner isLoading />;
  }

  return (
    <div id="App">
      {isLoggedIn ? <ActionsDispatcher /> : null}
      {shouldShowSAPDownTimePopup && <SAPDownTime />}
      {isLoggedIn && isAddProductToWishlistModalVisible ? (
        <AddProductToWishListModal />
      ) : null}
      <RouterRenderer />
    </div>
  );
}
