import environment from 'environments/environment.dev';

import HttpClient from './apiHttpClient';

const PUNCHOUT_ENDPOINT = '/v1.0/punchout/cacheddata';
const PUNCHOUT_SIMULATE_ENDPOINT = '/v1.0/punchout/punchout-simulate';

/**
 * @typedef {Object} Entity
 * @property {string} domain - The domain of the entity
 * @property {string} value - The value of the entity
 */

/**
 * @typedef {Object} CxmlData
 * @property {string} type - The type of the cXML (e.g., 'Ariba')
 * @property {string} payloadId - The unique identifier for the payload
 * @property {string} timeStamp - The timestamp of the transaction
 * @property {string} culture - The culture/locale information
 * @property {Entity} supplier - The supplier information
 * @property {Entity} buyer - The buyer information
 * @property {Entity} sender - The sender information
 * @property {string} buyerCookie - The buyer's cookie
 * @property {string} currency - The currency code
 * @property {string} unitOfMeasure - The unit of measure
 * @property {string} classification - The classification
 * @property {string} manufacturerPartId - The manufacturer part ID
 * @property {string} manufacturerName - The manufacturer name
 * @property {number} leadTime - The lead time value
 */

/**
 * @typedef {Object} ApiResponse
 * @property {CxmlData} data - The response data
 * @property {number} status - HTTP status code
 * @property {string} message - Response message
 */

const fapiClient = new HttpClient(environment.shopFAPIUrl);

/**
 * Service for handling punchout-related operations
 * @namespace
 */
export const punchoutService = {
  /**
   * Retrieves the cached cXML data from the server
   * @async
   * @function
   * @returns {Promise<ApiResponse>} The cached cXML data
   * @throws {Error} If the request fails
   * @example
   * try {
   *   const response = await punchoutService.getCachedCxmlData();
   *   console.log(response.data);
   * } catch (error) {
   *   console.error('Failed to fetch cached cXML data:', error);
   * }
   */
  getCachedCxmlData() {
    return fapiClient.get(PUNCHOUT_ENDPOINT);
  },

  /**
   * Deletes the cached cXML data from the server
   * @async
   * @function
   * @returns {Promise<ApiResponse>} The response from the server
   * @throws {Error} If the request fails
   * @example
   * try {
   *   await punchoutService.deleteCachedCxmlData();
   *   console.log('Cache cleared successfully');
   * } catch (error) {
   *   console.error('Failed to clear cache:', error);
   * }
   */
  deleteCachedCxmlData() {
    return fapiClient.delete(PUNCHOUT_ENDPOINT);
  },

  /**
   * Generates a dynamic cXML template
   * @async
   * @function
   * @param {Object} params - The parameters for generating the template
   * @param {CxmlData} params.cxmlData - The cXML data object
   * @param {string} params.sessionId - The session identifier
   * @returns {Promise<ApiResponse>} The generated template
   * @throws {Error} If the request fails
   * @example
   * try {
   *   const template = await punchoutService.generateDynamicCxmlTemplate({
   *     cxmlData: { type: 'Ariba', ... },
   *     sessionId: 'session123'
   *   });
   *   console.log(template);
   * } catch (error) {
   *   console.error('Failed to generate template:', error);
   * }
   */
  generateDynamicCxmlTemplate({ cxmlData, sessionId }) {
    return fapiClient.post(PUNCHOUT_SIMULATE_ENDPOINT, {
      body: {
        ...cxmlData,
        culture: cxmlData.culture || 'de-DE',
      },
      queryParams: {
        sessionId,
      },
    });
  },
};
