import './i18n';
import 'antd/dist/antd.css';
import './styles.scss';
import './assets/img/Kaercher_Logo_2015.png';

import B2becSpinner from 'components/B2becSpinner';
import ErrorBoundary from 'components/ErrorBoundary';
import { configureAmplify } from 'config/amplify';
import ExternalFileScripts from 'ExternalFileScripts';
import { SESSION_BASED_EXPIRY_TIME } from 'libs/constants';
import { setCookie } from 'libs/utils/cookies';
import { history } from 'libs/utils/history';
import { hasAllPropertiesValid } from 'libs/utils/object';
import { getInfoFromUrl } from 'libs/utils/punchoutHandler';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router as ReactRouter } from 'react-router-dom';

import App from './App';
import store from './store';

configureAmplify();

const getPunchoutData = () => {
  const punchoutTypes = ['oci', 'procurement'];
  const { pathname = '', href = '' } = window?.location;

  const isPunchout = pathname.includes('punchout');
  const pathParts = href.split('/');

  const punchoutType = punchoutTypes.find((type) => {
    return pathParts.includes(type);
  });

  if (!isPunchout || pathParts.includes('technical-login')) {
    return {};
  }

  const queryKeys = ['accessKey', 'secret', 'HOOK_URL', 'sessionId', 'type'];
  if (punchoutType === 'oci') {
    const [encodedSecrets, queryString] =
      pathParts[pathParts.length - 1].split('?');
    const decodedSecrets = window.atob(encodedSecrets.slice(1));

    return getInfoFromUrl(
      queryString
        ? `${decodedSecrets}${`&${queryString}`}`
        : `${decodedSecrets}`,
      queryKeys,
      { type: punchoutType }
    );
  }

  const decodedQueryString = window.atob(pathParts[pathParts.length - 1]);
  const [, queryString] = decodedQueryString.split('?');
  return getInfoFromUrl(queryString, queryKeys, {
    type: 'cxml',
  });
};

const punchoutData = getPunchoutData();

if (hasAllPropertiesValid(punchoutData)) {
  const { accessKey, secret, type } = punchoutData;

  const expiredTime = new Date().getTime() + SESSION_BASED_EXPIRY_TIME;

  if (type === 'oci') {
    setCookie({
      name: 'HOOK_URL',
      value: decodeURIComponent(punchoutData?.HOOK_URL),
      expires: expiredTime,
    });
  } else {
    setCookie({
      name: 'PUNCHOUT_SESSION_ID',
      value: decodeURIComponent(punchoutData?.sessionId),
      expires: expiredTime,
    });
    // Set the template type for procurement punchout eg. Ariba, Coupa,..
    setCookie({
      name: 'PUNCHOUT_TEMPLATE_TYPE',
      value: decodeURIComponent(punchoutData?.type),
      expires: expiredTime,
    });
  }

  const credential = new URLSearchParams({
    username: accessKey,
    password: secret,
  });

  const encodedCredential = window.btoa(credential.toString());

  window.location.replace(
    `/technical-login/${type}/${encodedCredential[0]}${encodedCredential}`
  );
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <React.Suspense fallback={<B2becSpinner isLoading />}>
          <ReactRouter history={history}>
            <ExternalFileScripts />
            <App />
          </ReactRouter>
        </React.Suspense>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
