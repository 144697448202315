const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  isFetchingUserDetails: false,
  userDetails: null,
  isChangingLanguage: false,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeUserSettingsLanguage(state, { payload }) {
      const { language, countryId } = payload;

      state.userSettings = {
        ...state.userSettings,
        language,
        culture: `${language}-${countryId}`,
      };
    },
    getUserDetails(state) {
      state.isFetchingUserDetails = true;
    },
    getUserDetailsSucceeded(state, { payload }) {
      state.isFetchingUserDetails = false;
      state.userDetails = payload;
    },
    getUserDetailsFailed(state) {
      state.isFetchingUserDetails = false;
    },
    requestChangeLanguage(state) {
      state.isChangingLanguage = true;
    },
    requestChangeLanguageSucceeded(state) {
      state.isChangingLanguage = false;
    },
    requestChangeLanguageFailed(state) {
      state.isChangingLanguage = false;
    },
    getCurrentTechnicalUserInfoFailed(state) {
      state.isFetchingUserDetails = false;
    },
    clearUserDetails(state) {
      state.userDetails = null;
    },
  },
});

export const userActions = {
  ...user.actions,
};

export default user.reducer;
