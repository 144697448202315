import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  voucherList: [],
  hasError: false,
};

const voucherSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    getVoucherList: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    getVoucherListSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.voucherList = payload;
      state.hasError = false;
    },
    getVoucherListFailure: (state) => {
      state.isLoading = false;
      state.voucherList = [];
      state.hasError = true;
    },
  },
});

export const voucherActions = voucherSlice.actions;

export default voucherSlice.reducer;
