import './styles.scss';

import ConfirmModal from 'components/ConfirmModal';
import { NotificationManager } from 'components/Notification';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useAsync from 'hooks/useAsync';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { linkGenerator } from 'libs/utils/language';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { adminFapiVoucher } from 'services/fapi/adminFapi';
import {
  selectHasErrorVouchersList,
  selectIsLoadingVouchersList,
  selectVouchersList,
} from 'store/selectors/voucherSelectors';
import { voucherActions } from 'store/slices/voucherSlice';

import Title from './components/Title';
import VouchersTable from './components/VouchersTable';

function VoucherManagement({
  title = 'pageTitle.voucherManagement',
  breadcrumbs,
  pageId = '24',
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedVoucher, setSelectedVoucher] = useState();

  const voucherList = useSelector(selectVouchersList);
  const isLoading = useSelector(selectIsLoadingVouchersList);
  const hasError = useSelector(selectHasErrorVouchersList);

  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  useAdobeAnalysis(null, {
    pageId,
    pageName: 'Voucher Management',
    pageType: PAGE_TYPES.VOUCHER,
  });

  const {
    execute: executeDeleteVoucherInfo,
    status: deleteVoucherInfoStatus,
    error: deleteVoucherInfoError,
  } = useAsync(adminFapiVoucher.deleteVoucherInfo, false);

  const reloadList = useCallback(() => {
    dispatch(voucherActions.getVoucherList());
  }, [dispatch]);

  const handleEditVoucher = (record) => {
    history.push(linkGenerator(`/voucher/${record.id}`));
  };

  useEffect(() => {
    if (deleteVoucherInfoStatus === 'success') {
      reloadList();
      NotificationManager.success({
        message: 'notification.success.delete',
      });
    }
    if (deleteVoucherInfoError) {
      NotificationManager.error({
        message: 'notification.error.delete',
      });
    }
  }, [deleteVoucherInfoStatus, deleteVoucherInfoError, reloadList]);

  const renderConfirmModal = useCallback(
    (recordId) => {
      if (!recordId) {
        return undefined;
      }
      return (
        <ConfirmModal
          isShowModal
          setIsShowModal={() => setSelectedVoucher(undefined)}
          modalTitle={t('voucher.deleteVoucher')}
          modalContent={t('voucher.deleteVoucherConfirm')}
          confirmActionHandler={() => executeDeleteVoucherInfo(recordId)}
        />
      );
    },
    [executeDeleteVoucherInfo, t]
  );

  const handleAddVoucher = () => {
    history.push(linkGenerator('/voucher'));
  };

  useEffect(() => {
    dispatch(voucherActions.getVoucherList());
  }, [dispatch]);

  useEffect(() => {
    if (hasError) {
      NotificationManager.error({
        message: 'notification.error.load',
      });
    }
  }, [hasError]);

  return (
    <div className="voucher-management__container">
      <Title handleAddVoucher={handleAddVoucher} />
      <VouchersTable
        voucherList={voucherList}
        handleEditVoucher={handleEditVoucher}
        handleDeleteVoucher={setSelectedVoucher}
        isLoading={isLoading}
      />
      {renderConfirmModal(selectedVoucher?.id)}
    </div>
  );
}

export default VoucherManagement;
