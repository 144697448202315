import B2becButton from 'components/B2becButton';
import useSessionBased from 'hooks/useSessionBased';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ProductService } from 'services';
import { selectIsProcurementTechnicalUserCxml } from 'store/selectors/userSelector';

const DisisHomeButton = () => {
  const { t } = useTranslation();
  const { sessionId } = useSessionBased();
  const [disisHomePageURL, setDisisHomePageURL] = useState('/#');
  const [isLoading, setIsLoading] = useState(false);
  const isTechnicalUserCXML = useSelector(selectIsProcurementTechnicalUserCxml);
  const sparePartsOpenMode = isTechnicalUserCXML ? '_self' : '_blank';

  useEffect(() => {
    const getDisisHomePageURL = async () => {
      try {
        setIsLoading(true);
        const { data } = await ProductService.getDisisHomePageLink(sessionId);
        setDisisHomePageURL(data);
      } catch (error) {
        console.error('Error fetching DISIS home page URL:', error?.message);
      } finally {
        setIsLoading(false);
      }
    };

    getDisisHomePageURL();
  }, [sessionId]);

  return (
    <div style={{ marginTop: '24px' }}>
      <a
        href={disisHomePageURL}
        target={sparePartsOpenMode}
        rel="noopener noreferrer"
      >
        <B2becButton loading={isLoading} ghost>
          {t('sparePartsFinder.button.toDisis')}
        </B2becButton>
      </a>
    </div>
  );
};

export default DisisHomeButton;
