import { LoadingOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import AddProduct from 'components/AddProductModal/AddProduct';
import B2becInfoLayout from 'components/B2becInfoLayout';
import B2becPagination from 'components/B2becPagination';
import Translator from 'components/B2becTranslation';
import CustomButton from 'components/CustomButton';
import { DownloadIcon, UploadIcon } from 'components/Icons';
import ImportConfirmModal from 'components/ImportConfirmModal';
import { useBreadcrumbs, useDocumentTitle } from 'hooks';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useModal from 'hooks/useModal';
import usePermission from 'hooks/usePermission';
import { PAGE_SIZE, SORT_ORDERS } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import {
  CONFIG_DATA_TYPES,
  CONFIG_LEVELS,
  CONFIG_NAMES,
} from 'libs/constants/configs';
import { MODULE_ADMINISTRATION_RIGHTS } from 'libs/constants/modulerights';
import { formatMaterialID } from 'libs/utils/material-number';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  adminFapiConfiguration,
  fapiProductPortfolioService,
} from 'services/adminFapi';
import {
  addedProductsSelector,
  selectedProductSelector,
  selectTotalPortfolios,
} from 'store/selectors/portfolioSelector';
import {
  addProduct,
  clearAddedProducts,
  getPortfolioOverviewList,
  resetPorfolioOverviewList,
} from 'store/slices/portfolioSlice';

import ProductPortfolioEdit from './components/ProductPortfolioEdit';
import ProductScope from './components/ProductScope';
import SearchBar from './components/SearchBar';
import styles from './Portfolio.module.scss';
import { exportPorfolio, importPorfolio } from './ProductHelper';
import PortfolioOverviewList from './ProductList';

const SORT_FIELDS = {
  productName: 'productName',
};

const ProductManagement = (props) => {
  const { title, breadcrumbs, pageId } = props;

  useBreadcrumbs(breadcrumbs);
  useDocumentTitle(title);

  const [isUploading, setIsUploading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isShowImportConfirmation, setIsShowImportConfirmation] =
    useState(false);
  const [hideListPriceCustomerConfigs, setHideListPriceCustomerConfigs] =
    useState([]);
  const [showHideListPriceActiveForCountry, setShowHideListActiveForCountry] =
    useState(false);
  const [
    showHideListPriceActiveForCustomer,
    setShowHideListPriceActiveForCustomer,
  ] = useState(false);

  useAdobeAnalysis(null, {
    pageId,
    pageName: 'product management overview',
    pageType: PAGE_TYPES.PRODUCT_MANAGEMENT,
  });

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const totalPortfolios = useSelector(selectTotalPortfolios);
  const selectedProduct = useSelector(selectedProductSelector);
  const addedPorfolioProducts = useSelector(addedProductsSelector);

  const [selectedScope, setScope] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy] = useState(SORT_FIELDS.productName);
  const [orderBy] = useState(SORT_ORDERS.ascend);
  const { Modal: AddProductModal, toggleModal } = useModal();

  const countryId = selectedScope?.countryId;
  const customerNumber = selectedScope?.customerNumber;
  const { hasPermission: canUpdateProductPortfolio } = usePermission(
    MODULE_ADMINISTRATION_RIGHTS.EDIT_PPM
  );

  const selectScopeHandler = useCallback((scope) => {
    setCurrentPage(1);
    setScope(scope);
  }, []);

  const handlePageChange = useCallback((current) => {
    setCurrentPage(current);
  }, []);

  const handleSearchClick = (searchQuery) => {
    setCurrentPage(1);

    dispatch(
      getPortfolioOverviewList({
        ...selectedScope,
        pageNumber: currentPage,
        searchTerm: searchQuery,
        sortBy,
        orderBy,
      })
    );
  };

  const handleGetProductList = useCallback(() => {
    dispatch(
      getPortfolioOverviewList({
        ...selectedScope,
        pageNumber: currentPage,
        searchTerm,
        sortBy,
        orderBy,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, dispatch, orderBy, selectedScope, sortBy]);

  const handleGetHideListPriceGlobalConfig = useCallback(async () => {
    // Get config by countryId and customerNumber
    const { countryId: selectedCountryId } = selectedScope;
    const response =
      await adminFapiConfiguration.getCustomersConfigKeyByCountry(
        CONFIG_NAMES.HIDE_LIST_PRICE_GLOBAL,
        selectedCountryId,
        CONFIG_DATA_TYPES.STRING
      );

    let hideListPriceActiveForCountry = false;
    const mappedConfigList = response.data.reduce((acc, config) => {
      if (config.configurationValue === 'true') {
        hideListPriceActiveForCountry = true;
      }
      acc[config.sapCustomerNumber] = config.configurationValue;
      return {
        ...acc,
      };
    }, {});

    setShowHideListActiveForCountry(hideListPriceActiveForCountry);
    setHideListPriceCustomerConfigs(mappedConfigList);
  }, [selectedScope]);

  const onExportPortfolio = useCallback(async () => {
    setIsExporting(true);
    await exportPorfolio(customerNumber);
    setIsExporting(false);
  }, [customerNumber]);

  const onImportPortfolio = useCallback(
    async ({ file }) => {
      setIsUploading(true);
      setIsShowImportConfirmation(false);
      await importPorfolio(file, selectedScope, handleGetProductList);
      setIsUploading(false);
    },
    [handleGetProductList, selectedScope]
  );

  const handleSearchTermChange = (e) => {
    let valueTemp = e.target.value;
    const reg = /^[0-9]{8}$/;

    if (reg.test(valueTemp)) {
      valueTemp = formatMaterialID(valueTemp);
    }

    setSearchTerm(valueTemp);
  };

  const handleCloseAddProductModal = useCallback(() => {
    toggleModal(false);
    handleGetProductList();
  }, [handleGetProductList, toggleModal]);

  useEffect(() => {
    if (!countryId) {
      dispatch(resetPorfolioOverviewList());
      return;
    }

    handleGetProductList();
  }, [handleGetProductList, countryId, dispatch]);

  useEffect(() => {
    if (!selectedScope) return;

    if (selectedScope.customerNumber) {
      setShowHideListPriceActiveForCustomer(
        hideListPriceCustomerConfigs[selectedScope.customerNumber] === 'true'
      );
    } else if (selectedScope.countryId) {
      handleGetHideListPriceGlobalConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleGetHideListPriceGlobalConfig, selectedScope]);

  const onAddPortfolio = () => {
    if (!countryId) return;
    dispatch(clearAddedProducts());
    toggleModal(true);
  };

  const handleAddProductToPPM = useCallback(
    ({ materialNumber }) => {
      dispatch(addProduct({ materialNumber, countryId, customerNumber }));
    },
    [countryId, customerNumber, dispatch]
  );

  const handleSearchProductForPPM = useCallback(
    ({
      query,
      countryId: countryIdVal,
      customerNumber: customerNumberVal,
      cancelToken,
    }) => {
      return fapiProductPortfolioService.getSuggestionsProductsList({
        query,
        countryId: countryIdVal,
        customerNumber: customerNumberVal,
        cancelToken,
      });
    },
    []
  );

  const renderConfirmModalContent = () => {
    if (CONFIG_LEVELS.COUNTRY === selectedScope?.level) {
      return (
        <Translator
          value="productManagement.importProductConfirmModal.modalContent"
          variables={{ country: selectedScope.countryId }}
          context="country"
          isComponent
        />
      );
    }
    if (CONFIG_LEVELS.CUSTOMER === selectedScope?.level) {
      return (
        <Translator
          value="productManagement.importProductConfirmModal.modalContent"
          variables={{
            country: selectedScope.countryId,
            customer: selectedScope.customerNumber,
          }}
          context="customer"
          isComponent
        />
      );
    }
    return (
      <Translator
        value="productManagement.importProductConfirmModal.modalContent"
        context="all"
        isComponent
      />
    );
  };

  return (
    <div className="product-management__container">
      <B2becInfoLayout>
        <B2becInfoLayout.Title
          title={t('pageTitle.productManagement')}
          titleExtra={
            <div className={styles['portfolio-buttons-container']}>
              <CustomButton
                onClick={onExportPortfolio}
                className={styles['header-buttons']}
                disabled={isExporting}
              >
                <DownloadIcon />
                {t('buttonTexts.export')}
              </CustomButton>
              {canUpdateProductPortfolio && (
                <>
                  <CustomButton
                    disabled={isUploading}
                    onClick={() => setIsShowImportConfirmation(true)}
                    className={styles['header-buttons']}
                  >
                    {isUploading ? <LoadingOutlined /> : <UploadIcon />}
                    {t('buttonTexts.import')}
                  </CustomButton>
                  <CustomButton
                    onClick={onAddPortfolio}
                    className={styles['header-buttons']}
                  >
                    {t('buttonTexts.add')}
                  </CustomButton>
                </>
              )}
            </div>
          }
        >
          <ProductScope selectScopeHandler={selectScopeHandler} />
        </B2becInfoLayout.Title>
        <B2becInfoLayout.Content>
          <Space direction="vertical" style={{ marginBottom: '20px' }}>
            {showHideListPriceActiveForCountry && (
              <>
                <Typography.Text type="danger">
                  {t('productManagement.hideListPriceActiveForCountryHint')}
                </Typography.Text>
                {showHideListPriceActiveForCustomer && (
                  <Typography.Text type="danger">
                    {t('productManagement.hideListPriceActiveForCustomerHint')}
                  </Typography.Text>
                )}
              </>
            )}
          </Space>
          <SearchBar
            searchTerm={searchTerm}
            onSearchTermChange={handleSearchTermChange}
            onSearchClick={handleSearchClick}
          />
          {selectedProduct ? (
            <ProductPortfolioEdit handleGetProductList={handleGetProductList} />
          ) : (
            <>
              <PortfolioOverviewList
                canUpdateProductPortfolio={canUpdateProductPortfolio}
              />
              <B2becPagination
                current={currentPage}
                pageSize={PAGE_SIZE[10]}
                total={totalPortfolios}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </B2becInfoLayout.Content>
        <AddProductModal width={800} closable={false} footer={null}>
          <AddProduct
            countryId={selectedScope?.countryId}
            customerNumber={selectedScope?.customerNumber}
            onClose={handleCloseAddProductModal}
            addedProducts={addedPorfolioProducts}
            handleAddProduct={handleAddProductToPPM}
            handleSearchProducts={handleSearchProductForPPM}
          />
        </AddProductModal>
        <ImportConfirmModal
          isShowModal={isShowImportConfirmation}
          setIsShowModal={setIsShowImportConfirmation}
          modalTitle={t(
            'productManagement.importProductConfirmModal.modalTitle'
          )}
          modalContent={renderConfirmModalContent()}
          confirmActionHandler={onImportPortfolio}
          onImportPortfolio={onImportPortfolio}
        />
      </B2becInfoLayout>
    </div>
  );
};

ProductManagement.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

ProductManagement.defaultProps = {};

export default ProductManagement;
